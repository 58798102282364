<template>
    <div>
        <h1>Em construção</h1>
    </div>
</template>

<script>
    export default {
        name: 'LogsPage'
    }
</script>

<style lang="scss" scoped>

</style>